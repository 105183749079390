import {Link} from "react-router-dom";
import Logo from "../img/jetwaste_logo.png";

export default function Error() {

    return (
        <div className='container p-10'>
            <img src={Logo} alt='logo' className='pb-5'/>
            <h2 className='text-2xl'>
                Error - 404
            </h2>
            <p>Page cannot be found.</p>
            <br/>
            <Link to='/' className='bg-yellow-300 p-2 rounded'>Continue to Homepage</Link>
        </div>
    )
}