import { Outlet } from "react-router-dom";
import logo from "../img/jw_main_landing_page.jpeg";

export default function Main() {
    return (
        <>
            <div style={{ textAlign: 'center', minHeight: '44vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <span style={{ fontSize: '24px', fontWeight: '600', marginTop: 'auto' }}>Portal development in-progress</span>
                <span style={{ fontSize: '18px', marginTop: '6px' }}>This customer portal is currently undergoing development. To view your current invoices with Jet Waste, please click the "Invoice" tab above.</span>
            </div>
        </>
    )
}