import { Navigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

// @ts-ignore
const ProtectedRoute = ({children}) => {
    // @ts-ignore
    const { auth } = useAuth();

    if (!auth.accessToken) {
        return <Navigate to='/'/>
    }
    return children
}
export default ProtectedRoute