import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/outline";
import { BACKEND_URL } from "../commons";
import { Spinner } from "./Loaders";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFView {
    invoiceRid: number
}

export default function PDFViewer(props: PDFView) {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    // @ts-ignore
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleNextPage() {
        const newPage = pageNumber + 1
        if (newPage <= numPages) {
            setPageNumber(newPage)
        }
    }

    function handlePreviousPage() {
        const newPage = pageNumber - 1
        if (newPage > 0) {
            setPageNumber(newPage)
        }
    }

    return (
        <div className='flex justify-center w-full h-full'>
            <div className='flex'>
                <div>
                    <div className='flex justify-between items-center py-8' style={{ width: 900 }}>
                        <div className='border-l-4 border-slate-700 pl-5 uppercase'>
                            pdf view
                        </div>
                        {/* <button
                            className="w-100 bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                            <DownloadIcon className='w-5 h-5 mr-2'/>
                            <span>Download</span>
                        </button> */}
                    </div>
                    <Document
                        file={BACKEND_URL + "/invoice/file/" + props.invoiceRid}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <div className='flex gap-3 items-center justify-center py-10 my-10'>
                                <Spinner />
                                <div>loading invoice...</div>
                            </div>
                        }
                        noData={<div>Could not load PDF</div>}

                        className='border border-gray-300 drop-shadow-lg'>
                        <Page
                            pageNumber={pageNumber}
                            width={900}
                        />
                    </Document>
                    <div className='bg-white p-5 flex justify-center'>
                        <div className='flex items-center'>
                            <button onClick={handlePreviousPage} className="mx-2 h-10 w-10" aria-hidden="true">
                                <ChevronLeftIcon />
                            </button>
                            <div>
                                {pageNumber} of {numPages}
                            </div>
                            <button onClick={handleNextPage} className="mx-2 h-10 w-10" aria-hidden="true">
                                <ChevronRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}