interface SimpleContainerParams {
    title: string;
    children: any;
}

export default function SimpleContainer(props: SimpleContainerParams) {
    return (
        <div className='flex flex-col'>
            <div className='text-3xl font-bold uppercase py-2 my-2'>
                {props.title}
            </div>
            <hr/>
            <div>
                {props.children}
            </div>
        </div>
    )
}