import background from "../img/jetwaste_bg.png";
import logo from "../img/jw_logo_new_white.png";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from '../api/axios'
import { Spinner } from "../components/Loaders";
import useAuth from "../hooks/useAuth";

const LOGIN_ENDPOINT = '/user/auth'

// @ts-ignore

function LoginModal() {

    //@ts-ignore
    const { setAuth } = useAuth()

    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)

    const handleLogin = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        setLoginLoading(true)
        setErrMsg('')
        try {
            const response = await axios.post(LOGIN_ENDPOINT,
                JSON.stringify({ username: username, password: password }),
                {
                    headers: { 'Content-Type': 'application/json' }
                })
            const accessToken = response.data.token;
            const auth = { username, accessToken }
            setAuth(auth)
            navigate('/app/invoice')
        } catch (err) {
            console.error(err)
            // @ts-ignore
            if (!err?.response) {
                setErrMsg('No response from server')
                // @ts-ignore
            } else if (err.response.status === 401) {
                setErrMsg('Incorrect username or password')
            } else {
                setErrMsg('Login Failed')
            }
        }
        setLoginLoading(false)
    }

    // styling
    const inputStyle = { fontSize: '24px' }
    const inputBoxStyle = "bg-transparent mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-500 focus:ring-0 focus:border-yellow-300"

    return (
        <>
            <div className='flex border-gray-400 items-center pb-10'>
                <img src={logo} style={{ height: 60 }} className='ml-20 mr-8' />
                <div style={{ color: '#fff' }}
                    className='text-3xl antialiased font-bold uppercase text-gray-800 border-l-4 border-yellow-300 pl-8'> Customer
                    Portal
                </div>
            </div>
            <div className='grid lg:grid-cols-5'>
                <div className='px-5 md:mr-20 md:px-20 py-20 bg-white rounded-r-lg drop-shadow-2xl col-span-4'>
                    <div className='mb-5 text-3xl text-gray-600 font-bold'>Sign into your account</div>
                    <div className='w-full'>
                        {
                            loginLoading ?
                                <div className='flex items-center my-10 text-slate-900 gap-3'>

                                    <Spinner />
                                    <span>Loading...</span>

                                </div> :
                                <>
                                    <span className="text-gray-700 pb-2">Username</span>
                                    <input
                                        onChange={(e) => setUsername(e.target.value)}
                                        type="text"
                                        style={inputStyle}
                                        placeholder={''}
                                        className={inputBoxStyle}
                                        required
                                    />
                                    <span className="text-gray-700 flex justify-between mt-5"><span>Password</span></span>
                                    <input
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        style={inputStyle}
                                        placeholder={''}
                                        className={inputBoxStyle}
                                        required
                                    />
                                </>
                        }
                        {
                            errMsg ?
                                <div className='flex text-red-500 items-center mt-10'>
                                    <i className="fa-solid fa-triangle-exclamation px-3"></i>
                                    <div>{errMsg}</div>
                                </div> : <div></div>
                        }
                        <div className='mt-10'>
                            <button
                                className="w-full bg-yellow-300 hover:bg-yellow-400 text-gray-700 font-bold py-2 px-4 border-b-4 border-yellow-500 hover:border-yellow-500 rounded"
                                onClick={handleLogin}
                            >
                                Login
                            </button>
                        </div>
                        <div className='mt-2'>
                            <button
                                className="w-full bg-gray-300 hover:bg-gray-200 text-gray-700 font-bold py-2 px-4 border-b-4 border-gray-400 hover:border-gray-400 rounded">Support
                            </button>
                        </div>
                        <p className='mt-2 text-gray-400'>
                            If you need assistance logging into your account, please use the support option, or contact
                            us by visiting this page.
                        </p>

                    </div>
                </div>
            </div>
            <div className='mt-10 pr-5 pl-20 text-gray-700'>
                <div style={{ color: '#fff' }} className='flex text-gray-700'>
                    <span>© 2022 - Jet Waste Services</span>
                    <span className='mx-2'>·</span>
                    <span>Contact</span>
                    <span className='mx-2'>·</span>
                    <span>portal by Synctivate</span>
                </div>
            </div>
        </>
    )
}

export default function LoginPage() {


    const backgroundStyle = {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '105vh',
        width: '105vw',
        zIndex: '-1',
        marginTop: '-10px',
        marginLeft: '-10px',
        opacity: 0.8,
    }

    return (
        <>
            <div className='overflow-hidden' style={{ width: '100vw', height: '100vh', position: 'absolute' }}>
                <div className='blur-sm' style={backgroundStyle}></div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-8' style={{ height: '100vh' }}>
                <div
                    className='drop-shadow-2xl col-span-1 lg:col-span-4 lg:col-end-8 h-full bg-white/50'
                    style={{ backgroundColor: 'rgba(16,31,46, 0.7)' }}>
                    <div className='mt-10 content-center overflow-hidden '>
                        <LoginModal />
                    </div>
                </div>
            </div>
        </>
    )
}