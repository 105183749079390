import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Error from "./pages/Error";
import Main from "./pages/Main";
import PortalLayout from "./layouts/PortalLayout";
import Invoice from "./pages/Invoice";
import {AuthProvider} from "./context/AuthProvider";

const root = ReactDOM.createRoot(
    // @ts-ignore
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/" element={<LoginPage/>}/>
                <Route path="app" element={<PortalLayout/>}>
                    <Route index element={<Main/>}/>
                    <Route path='invoice' element={<Invoice/>}/>
                    <Route path='option' element={<Main/>}/>
                </Route>
                <Route path="*" element={<Error/>}/>
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
