import {Disclosure} from "@headlessui/react";
import {Outlet} from "react-router-dom";
import SimpleContainer from "../containers/SimpleContainer";
import PDFViewer from "../components/PDFViewer";
import {useEffect, useState} from "react";
import authedAxios from "../api/authedAxios";
import {BACKEND_URL} from "../commons";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import {Spinner} from "../components/Loaders";
import AvailableInvoicesMenu from "../components/AvailableInvoicesMenu";


export default function Invoice() {

    // @ts-ignore
    const {auth} = useAuth();
    const [availableProperties, setProperties] = useState([])
    const [currentProperty, setCurrentProperty] = useState(null)
    const [currentInvoiceRid, setCurrentInvoiceRid] = useState(0)
    const [availableInvoices, setInvoices] = useState(null)
    const [loadingProperties, setLoadingProperties] = useState(true)


    useEffect(() => {
        try {
            // @ts-ignore
            authedAxios.get('/property/' + auth.username)
                .then((response) => {
                    setProperties(response.data)
                    setLoadingProperties(false)
                    setCurrentProperty(response.data[0].qb_rid)
                })

        } catch (err) {
            console.error(err)
        }

    }, [auth.username])

    useEffect(() => {
        if (currentProperty) {
            try {
                // @ts-ignore
                authedAxios.get('/property/invoices/' + currentProperty)
                    .then((response) => {
                        setInvoices(response.data)
                    })

            } catch (err) {
                console.error(err)
            }
        }
    }, [currentProperty]);

    let options: JSX.Element[] = []
    availableProperties.forEach((data) => { // @ts-ignore
        options.push(<option value={data.qb_rid}>{data.name}</option>)
    })

    const handlePropertySelect = (e: { target: { value: any; }; }) => setCurrentProperty(e.target.value)

    // styling
    const dropdownClasses = "block w-full mt-0 px-2 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"

    let renderedInvoiceList = <Spinner/>
    console.log(availableInvoices)
    // @ts-ignore
    if (availableInvoices) {
        renderedInvoiceList = <AvailableInvoicesMenu data={availableInvoices} invoiceRidCallback={setCurrentInvoiceRid}/>
    }

    // @ts-ignore
    return (
        <>
            <SimpleContainer title='Invoices'>
                <div className='grid grid-cols-6'>
                    <div className='col-span-2'>
                        <div>
                            <div className='py-2 my-2 px-2 text-slate-500'>Property</div>
                            <div className='px-3'>
                                {
                                    loadingProperties ?
                                        <div className='flex items-center'>
                                            <Spinner/>
                                            <div>Loading properties...</div>
                                        </div>
                                        :
                                        <select className={dropdownClasses}
                                                onChange={event => handlePropertySelect(event)}>
                                            {options}
                                        </select>

                                }
                            </div>
                        </div>
                        <div>
                            <div className='py-2 my-2 px-2 text-slate-500'>Available</div>
                            {renderedInvoiceList}
                        </div>
                    </div>
                    <div className="col-span-4 flex justify-center">
                        <PDFViewer invoiceRid={currentInvoiceRid}/>
                    </div>
                </div>
            </SimpleContainer>
        </>
    )
}