import {Fragment, useRef, useState} from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

interface InvoiceEntryProps {
    title: string,
    rid: number,
    callback: Function
}

// @ts-ignore
function InvoiceEntry(props: InvoiceEntryProps) {

    function handleClick() {
        console.log('CLICK!')
        props.callback(props.rid)
    }

    return (
        <div className='flex items-center justify-between py-2'>
            <div className='pl-4 text-slate-700 text-2xl font-bold'>{props.title}</div>
            <div className='px-4'>
                <div onClick={handleClick} className='cursor-pointer p-2 px-6 bg-yellow-300'>View PDF</div>
            </div>
        </div>
    )
}

function Collapse({title, children}: { title: any, children: any }) {

    const [opened, setOpen] = useState(false)

    const childContainer = useRef<HTMLDivElement>(null)
    const childStyle = opened ? {
        // @ts-ignore
        height: '100%',
        opacity: 100,
        overflow: 'hidden',
        display: 'block'
    } : {height: 0, opacity: 0, overflow: 'visible', display: 'none'}

    const icon = opened ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>

    console.log('opened', opened)

    return (
        <div className='flex flex-col'>
            <div onClick={() => setOpen(opened => !opened)}
                 className='flex justify-between items-center hover:cursor-pointer'>
                {title}
                <div className='px-3'>{icon}</div>
            </div>
            <div style={childStyle} className='collapse-content' ref={childContainer}>
                <div className='py-3'>
                    {children}
                </div>
            </div>
        </div>
    )
}

interface InvoiceMenuProps {
    data: any,
    invoiceRidCallback: (rid: number) => void
}

export default function AvailableInvoicesMenu(props: InvoiceMenuProps) {
    const entryData = props.data
    let years = [];
    if (entryData) {
        for (const [year, yearContent] of Object.entries(entryData)) {
            let months = [];
            // @ts-ignore
            for (const [month, monthContent] of Object.entries(yearContent)) {
                let invoiceEntries: JSX.Element[] = []

                // @ts-ignore
                monthContent.forEach((entry) => {
                    // @ts-ignore
                    invoiceEntries.push(<InvoiceEntry title={entry.name} rid={entry.qb_rid} callback={props.invoiceRidCallback}/>)
                })



                months.push(
                    <Collapse title={<div className='ml-4 pl-4 text-4xl border-l-2 border-gray-500'>{month}</div>}>
                        <div className='pl-8'>
                            {invoiceEntries}
                        </div>
                    </Collapse>
                )
            }

            years.push(
                <Collapse title={<div className='text-6xl font-bold'>{year}</div>}>
                    {months}
                </Collapse>
            )
        }
    }

    return (
        <>
            {years}
        </>
    );
}