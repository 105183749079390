import { NavLink, Outlet } from "react-router-dom";
import logo from "../img/jetwaste_logo.png";
import React, { Fragment } from 'react'
//@ts-ignore
import { Menu, Transition } from '@headlessui/react'
//@ts-ignore
import { ChevronDownIcon, UserIcon } from '@heroicons/react/solid'
import ProtectedRoute from "../components/ProtectedRoute";
import useAuth from "../hooks/useAuth";

interface NavOptionParams {
    to: any;
    text: any;
}

function NavOption(props: NavOptionParams) {

    const navClasses = 'py-3 hover:border-b-4 hover:border-yellow-300 ease-in-out transition-colors duration-200 flex justify-center'

    return (
        <NavLink
            to={props.to} end
            // @ts-ignore
            className={({ isActive }) => (isActive ? 'border-b-4 border-black ' : ' ') + navClasses}
        >
            <div>{props.text}</div>
        </NavLink>
    )
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function PortalLayout() {
    // @ts-ignore
    const { auth } = useAuth();
    return (
        <ProtectedRoute>
            {/* <div className='bg-yellow-400 text-black p-2 text-center'>
                Thank you for visiting the JWS Customer Portal! This portal is currently in development. If you experience any bugs or issues, please contact info@jetwaste.com. Thank you!
            </div> */}
            <div className='flex flex-col h-screen justify-between items-center content-center'>
                <div className='container mx-auto'>
                    <header style={{ backgroundColor: 'rgba(16,31,46, 1.0)' }} className="bg-transparent border-b-2 border-gray-100 flex justify-between">
                        {/*<img src={logo} className="App-logo" alt="logo" />*/}
                        <div className='flex items-center'>
                            <div style={{ color: '#fff' }} className='px-8 mr-8 font-bold uppercase border-r-2 border-gray-300'>Jet Waste Services
                            </div>
                            <div style={{ color: '#fff' }} className='grid grid-cols-4 gap-8'>

                                <NavOption to='/app' text='Home' />
                                <NavOption to='/app/invoice' text='Invoice' />
                                <NavOption to='/tickets' text='Tickets' />


                            </div>
                        </div>
                        <div className='flex items-center px-8'>
                            <div className='flex items-center gap-3'>
                                <div style={{ color: '#fff' }} className='text-slate-500 text-sm'>Help</div>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button
                                            className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-slate-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500">
                                            {auth.username}
                                            <UserIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    <a href="/home" className='block px-4 py-2 text-sm text-gray-700'>
                                                        Account Settings
                                                    </a>
                                                </Menu.Item>
                                                <form method="POST" action="#">
                                                    <Menu.Item>
                                                        <a href="/home"
                                                            className='block px-4 py-2 text-sm text-gray-700'>
                                                            Log Out
                                                        </a>
                                                    </Menu.Item>
                                                </form>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </header>
                </div>
                <main className="container mx-auto mb-auto px-8">
                    <Outlet />
                </main>
                <footer className="container mx-auto h-10 bg-gray-100 flex items-center text-gray-500 p-5">
                    <span>© 2022 - Jet Waste Services</span>
                    <span className='mx-2'>·</span>
                    <span>Contact</span>
                    <span className='mx-2'>·</span>
                    <span>portal by Synctivate</span>
                </footer>
            </div>
        </ProtectedRoute>
    )
}